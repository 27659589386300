import request from 'superagent';
import { UPDATE_STORY_ANALYSIS_SCORE } from './types';
import StoryChief from '../../storychief/index';
import processingStoryProperty from './processingStoryProperty';
import clearStoryStatusProperty from './clearStoryStatusProperty';
import updateStatusError from '../../storychief/actions/updateStatusError';

function updateStoryAnalysisScoreAsync(analysis) {
  return {
    type: UPDATE_STORY_ANALYSIS_SCORE,
    payload: analysis,
  };
}

export default function updateStoryAnalysisScore(storyId, analysis) {
  const property = 'analysis';
  return (dispatch, getState) => {
    dispatch(processingStoryProperty(property));
    request
      .put(`${StoryChief.apiBasePath}/stories/${storyId}`)
      .set('X-Lock-Token', getState().lock.token)
      .set('Accept', 'application/json')
      .set('X-Requested-With', 'XMLHttpRequest')
      .set('X-CSRF-TOKEN', StoryChief.csrfToken)
      .set('X-Socket-ID', window.Echo.socketId())
      .send({
        analysis_readability_score: analysis.readability.score,
        analysis_seo_score: analysis.seo.score,
      })
      .end((err, res) => {
        if (res && res.ok) {
          dispatch(updateStoryAnalysisScoreAsync(analysis));
          dispatch(clearStoryStatusProperty(property));
        } else {
          dispatch(updateStatusError(res, err));
        }
      });
  };
}
