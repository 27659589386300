import { escape } from 'lodash-es';
import getTitlePlainText from '@/editor/utils/getTitlePlainText';

function getSeoTitleWidth(story) {
  let width = 0;
  const storyTitle =
    getTitlePlainText(story.title) === 'Untitled article' ? '' : getTitlePlainText(story.title);
  const title = story.seo_title !== '' ? story.seo_title : storyTitle;
  const titleDiv = document.createElement('div');
  titleDiv.innerHTML = escape(title);
  titleDiv.style.cssText =
    'display:inline; font-size:18px; font-weight: 400; font-family: Arial,Helvetica,sans-serif;visibility:hidden';
  document.body.appendChild(titleDiv);
  width = titleDiv.offsetWidth;
  titleDiv.remove();
  return width;
}

export default getSeoTitleWidth;
