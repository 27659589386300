import getWordCount from '../../editor/utils/getWordCount';
import getReadTime from '../../storychief/utils/getReadTime';
import getParagraphCount from '../../editor/utils/getParagraphCount';
import getCharCount from '../../editor/utils/getCharCount';
import getWhitespaceCount from '../../editor/utils/getWhitespaceCount';
import getMediaCount from '../../editor/utils/getMediaCount';
import getLinkCount from '../../editor/utils/getLinkCount';

function getStoryAnalysisContent(title, content) {
  const wordCount = getWordCount(content);

  return {
    wordCount,
    readingTime: getReadTime(wordCount),
    paragraphCount: getParagraphCount(content),
    titleCharCount: getCharCount(title),
    charCount: getCharCount(content),
    charsExcludingWhitespaces: getCharCount(content) - getWhitespaceCount(content),
    mediaCount: getMediaCount(content),
    linkCount: getLinkCount(content),
  };
}

export default getStoryAnalysisContent;
