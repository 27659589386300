export default function getMediaCount(contentState) {
  let mediaCount = 0;
  const blocks = contentState ? contentState.getBlockMap() : [];
  if (blocks) {
    blocks.forEach((block) => {
      if (block.type === 'atomic') {
        const metadata = [...block.data];
        metadata.forEach((data) => {
          if (data[1] === 'image') mediaCount += 1;
          if (data[1] === 'embed') mediaCount += 1;
          if (data[0] === 'images') mediaCount += data[1].length;
          if (data[1] === 'video') mediaCount += 1;
        });
      }
    });
  }
  return mediaCount;
}
