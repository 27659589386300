import { convertFromRaw } from 'draft-js';
import { captureException } from '@sentry/react';
import { UPDATE_STORY_ANALYSIS } from './types';
import updateStoryAnalysisScore from './updateStoryAnalysisScore';
import getSeoTitleWidth from '../utils/seo/getSeoTitleWidth';
import { getModelIsLocked } from '../../presence/selectors/index';
import StoryChief from '@/storychief';
import yoastSeoConverter from '@/storyDestinations/converter/yoastSeoConverter';
import getTitlePlainText from '@/editor/utils/getTitlePlainText';
import getStoryAnalysisContent from '@/stories/utils/getStoryAnalysisContent';
import convertTextToSlug from '../../storychief/utils/convertTextToSlug';
import buildWorker from '@/storychief/utils/buildWorker';
// eslint-disable-next-line import/no-unresolved
import storyAnalysisWorkerUrl from '@/stories/workers/storyAnalysis.worker?url';

function updateStoryAnalysisAsync(analysis) {
  return {
    type: UPDATE_STORY_ANALYSIS,
    payload: analysis,
  };
}

let worker;

export default function updateStoryAnalysis() {
  return async (dispatch, getState) => {
    if (!worker) {
      if (import.meta.env.DEV) {
        worker = buildWorker(storyAnalysisWorkerUrl);
      } else {
        worker = new Worker(new URL('@/stories/workers/storyAnalysis.worker', import.meta.url));
      }
    }

    const { story } = getState();
    const titleContentState = story.title !== '' ? convertFromRaw(JSON.parse(story.title)) : null;
    const contentState = story.content !== '' ? convertFromRaw(JSON.parse(story.content)) : null;

    worker.onmessage = (e) => {
      const data = JSON.parse(e.data);
      if (data.stack) {
        if (StoryChief.shouldCaptureErrorsInSentry) {
          captureException(data);
        } else {
          window.console.log(data);
        }
        return;
      }
      data.content = getStoryAnalysisContent(titleContentState, contentState);
      dispatch(updateStoryAnalysisAsync(data));

      const storyIsLocked = getModelIsLocked(getState());
      if (!storyIsLocked) {
        if (
          data.readability.score !== story.analysis.readability.score ||
          data.seo.score !== story.analysis.seo.score
        ) {
          dispatch(updateStoryAnalysisScore(story.id, data));
        }
      }
    };

    const titleWidth = getSeoTitleWidth(story);

    worker.postMessage([
      {
        ...story,
        title: titleContentState ? getTitlePlainText(titleContentState) : 'Untitled article',
        contentSeoHtml: contentState ? yoastSeoConverter(contentState) : '',
        url: story.seo_slug !== '' ? story.seo_slug : convertTextToSlug(story.title),
      },
      titleWidth,
    ]);
  };
}
