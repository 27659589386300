export default function getParagraphCount(contentState) {
  let paragraphCount = 0;
  const blocks = contentState ? contentState.getBlockMap() : {};
  if (Object.keys(blocks).length) {
    blocks.forEach((block) => {
      if (block.type === 'unstyled') paragraphCount += 1;
      if (block.type === 'blockquote') paragraphCount += 1;
    });
  }
  return paragraphCount;
}
